import { PropsWithChildren } from "react";
import ReactSelect, { Props } from "react-select";
import ReactAsyncSelect, { Props as AsyncProps } from "react-select/async";

import { Control } from "design-system/xcore/Select/Control";
import { DropdownIndicator } from "design-system/xcore/Select/DropdownIndicator";
import { Group } from "design-system/xcore/Select/Group";
import { GroupHeading } from "design-system/xcore/Select/GroupHeading";
import { IndicatorSeparator } from "design-system/xcore/Select/IndicatorSeparator";
import { Input } from "design-system/xcore/Select/Input";
import { Menu } from "design-system/xcore/Select/Menu";
import {
  MultiValue,
  MultiValueContainer,
  MultiValueRemove
} from "design-system/xcore/Select/MultiValue";
import { NoOptionsMessage } from "design-system/xcore/Select/NoOptionsMessage";
import { Option } from "design-system/xcore/Select/Option";
import { Placeholder } from "design-system/xcore/Select/Placeholder";
import { SingleValue } from "design-system/xcore/Select/SingleValue";
import { ValueContainer } from "design-system/xcore/Select/ValueContainer";
import { useIsClient } from "utils/useIsClient";
import { useLayout } from "xcore";

export type OptionType = { label: string; value: any };

const Select = <T extends OptionType = OptionType>({ name, ...props }: PropsWithChildren<Props<T>>) => {
  const selectStyles = { menu: styles => ({ ...styles, zIndex: 2 }) };
  const { general, stringify } = useLayout();

  const isClient = useIsClient();

  if (!isClient) return null;

  return (
    <ReactSelect<T>
      styles={selectStyles}
      isClearable={false}
      components={{
        Option: Option as any,
        Menu,
        IndicatorSeparator,
        DropdownIndicator,
        ValueContainer,
        Control,
        Placeholder,
        Input,
        SingleValue,
        MultiValue,
        MultiValueRemove,
        MultiValueContainer,
        NoOptionsMessage,
        GroupHeading,
        Group
      }}
      placeholder={stringify(general.values.selectPlaceholder)}
      {...props}
    />
  );
};

export default Select;

export const AsyncSelect = <T extends OptionType = OptionType>({ name, ...props }: PropsWithChildren<AsyncProps<T>>) => {
  const selectStyles = { menu: styles => ({ ...styles, zIndex: 2 }) };
  const { general, stringify } = useLayout();

  const client = useIsClient();

  if (!client) return null;

  return (
    <ReactAsyncSelect<T>
      styles={selectStyles}
      isClearable={false}
      components={{
        Option: Option as any,
        Menu,
        IndicatorSeparator,
        DropdownIndicator,
        ValueContainer,
        Control,
        Placeholder,
        Input,
        SingleValue,
        MultiValue,
        MultiValueRemove,
        MultiValueContainer,
        NoOptionsMessage
      }}
      placeholder={stringify(general.values.selectPlaceholder)}
      {...props}
    />
  );
};
